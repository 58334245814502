import Vue from 'vue';
import Vuex from 'vuex';
import { saveToken } from '@/services/jwt';
import { S3ObjectsManager, lambdaInvoke } from '@/utils.js';
import { CONFIG_BUCKET_NAME } from '../config.constant';
import { EVColor } from '@/models/constants.js';

Vue.use(Vuex);

// TODO: Kirill K - this store was setup with only one store when we should have modules. We
// TODO: should break this up into different modules like deliveryStore, deleteIssueStore, etc
export const getterTypes = Object.freeze({
  GET_ITEMS_FROM_PATH_STRING: 'getItemsFromPathString',
});

export const actionTypes = Object.freeze({
  AUTHORIZE: `authorize`,
  DELETE_ISSUE: `deleteIssue`,
  SET_ACRONYM_DATA: `setAcronymData`,
  SET_VENDORS: `setVendors`,
  SET_COMMON_PREFIXES_BY_VENDOR: `setCommonPrefixesByVendor`,
  SET_ACRONYMS_BY_COLLECTION_ID: `setAcronymsByCollectionId`,
  SET_BATCHES_BY_COLLECTION_ID: `setBatchesByCollectionId`,
  SET_ACRONYMS_BY_BATCH: `setAcronymsByBatch`,
});

export const mutationTypes = Object.freeze({
  SET_AUTH: `setAuth`,
  SET_TOKEN_DATA: `setTokenData`,
  SET_TOAST: 'setToast',
  CLEAR_SELECTED_DATES: `clearSelectedDates`,
  SET_SELECTED_DATES: 'setSelectedDates',
});

export default new Vuex.Store({
  state: () => {
    return {
      loading: false,
      isAuthenticated: false,
      tokenData: null,
      initDataLoaded: false,
      configJSON: null,
      usersJSON: {},
      clients: [],
      vendors: [],
      collectionIdsByVendor: [],
      batches: [],
      acronymsForBatch: [],
      selectedDates: [],
      tableData: [],
      toast: {
        show: false,
        type: null,
        message: null,
        color: null,
        icon: null,
        timer: 500,
      },
    };
  },
  getters: {
    isAuthenticated: (state) => {
      console.log(`Auth settings ${process.env.VUE_APP_ENABLE_AUTH}`);
      if (process.env.VUE_APP_ENABLE_AUTH === 'false') {
        return true;
      }

      return state.isAuthenticated;
    },
    getItemsFromPathString: () => (pathString, selectedVendor) => {
      // break up the string into an array
      // you know the order of the array
      // so then filter out by the type
      const splitPathParts = pathString
        .replace(selectedVendor.folder, '')
        .split('/');
      return {
        vendorFolder: selectedVendor.folder,
        collection: splitPathParts[0],
        batch: splitPathParts[1],
        acronym: splitPathParts[2],
      };
    },
  },
  actions: {
    async authorize({ commit }, tokenData) {
      commit('setAuth', true);
      saveToken(tokenData.id_token);
      commit('setTokenData', tokenData);
    },
    async deleteIssue({ commit }, dataToDelete) {
      try {
        const response = await lambdaInvoke('DeleteIssue', dataToDelete);
        if (response.StatusCode !== 200) {
          throw new Error();
        }

        commit('setToast', {
          show: true,
          message:
            'Your request to delete issue(s) was placed successfully. You will be notified by email.',
          color: EVColor.green,
        });
      } catch (ex) {
        commit('setToast', {
          show: true,
          message: 'An error occurred with the delete issue request.',
          color: EVColor.red,
        });
      }
    },
    async setVendors({ commit }) {
      const s3ConfigManager = new S3ObjectsManager(CONFIG_BUCKET_NAME);
      const configJSON = await s3ConfigManager.getObjectJSON('gpa-config.json');
      commit('setVendors', configJSON.report.bucketsToCheck);
    },
    async setCommonPrefixesByVendor({ commit }, vendor) {
      const s3Manager = new S3ObjectsManager(vendor.bucket);
      const collectionIds = (
        await s3Manager.listCommonPrefixes(vendor.folder, '/')
      ).map((collection) => {
        return {
          collectionId: collection.replace(vendor.folder, ''),
          path: collection,
        };
      });

      commit('setCollectionIdsByVendor', collectionIds);
    },
    async setBatchesByCollectionId({ commit }, data) {
      const s3Manager = new S3ObjectsManager(data.vendor.bucket);
      const batches = (await s3Manager.listCommonPrefixes(data.path, '/')).map(
        (batchPath) => {
          return {
            batch: batchPath.replace(
              `${data.vendor.folder}${data.selectedItems.collection}/`,
              ''
            ),
            path: batchPath,
            selectedItems: data.selectedItems,
          };
        }
      );

      commit('setBatchesByVendor', batches);
    },
    async setAcronymsByBatch({ commit }, data) {
      const s3Manager = new S3ObjectsManager(data.vendor.bucket);
      const acronyms = (await s3Manager.listCommonPrefixes(data.path, '/')).map(
        (acronymPath) => {
          return {
            acronym: acronymPath.replace(
              `${data.vendor.folder}${data.selectedItems.collection}/${data.selectedItems.batch}/`,
              ''
            ),
            path: acronymPath,
            selectedItems: data.selectedItems,
          };
        }
      );

      commit('setAcronymsByPath', acronyms);
    },
    async setAcronymData({ commit }, path) {
      try {
        commit('setLoading', true);

        const s3ConfigManager = new S3ObjectsManager(CONFIG_BUCKET_NAME);
        const configJSON = await s3ConfigManager.getObjectJSON(
          'gpa-config.json'
        );
        const s3Manager = new S3ObjectsManager(
          configJSON['outDocuments']['archiveBucket']
        );
        commit('setConfigJSON', configJSON);

        const usersJSON = await s3ConfigManager.getObjectJSON('users.json');
        commit('setUsersJSON', usersJSON);
        commit('setClients', Object.keys(usersJSON.clients));

        const prefix = `${configJSON['outDocuments']['archivePrefix']}${path}`;
        const tableData = await s3Manager.listCommonPrefixes(prefix, '/');
        commit('setTableData', tableData);
      } catch (exception) {
        commit('setToast', {
          show: true,
          message: 'An error occurred getting the Acronym data.',
          color: EVColor.red,
        });
      } finally {
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAuth(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setSelectedDates(state, dates) {
      state.selectedDates = dates;
    },
    setCollectionIdsByVendor(state, collectionIds) {
      state.collectionIdsByVendor = collectionIds;
    },
    setBatchesByVendor(state, batches) {
      state.batches = batches;
    },
    setAcronymsByPath(state, acronyms) {
      state.acronymsForBatch = acronyms;
    },
    clearSelectedDates(state) {
      state.selectedDates = [];
    },
    setTokenData(state, data) {
      state.tokenData = data;
    },
    setToast(state, toastData) {
      if (!toastData.color) {
        // This is here to prevent the toast from flashing another color
        // temporarily when the user clicks the "close" button, for example
        // flashing from green to red on the success toasts
        toastData.color = state.toast.color;
      }

      state.toast = toastData;
    },
    setTableData(state, tableData) {
      state.tableData = tableData;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setConfigJSON(state, configJSON) {
      state.configJSON = configJSON;
    },
    setUsersJSON(state, usersJSON) {
      state.usersJSON = usersJSON;
    },
    setVendors(state, vendors) {
      state.vendors = vendors;
    },
  },
});
