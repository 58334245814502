import { parseJWTPayload } from './services/auth';
import { REGION, IDENTITY_POOL_ID, USER_POOL_ID } from './config.constant';
import AWS from 'aws-sdk';
import _ from 'lodash';
import moment from 'moment';
import { getToken } from './services/jwt';

export function getOptions() {
  if (process.env.VUE_APP_ENABLE_AUTH === 'false') {
    return {
      accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
    };
  } else return { credentials: getCreds() };
}

export function getOperatorByEmail(usersJSON, email) {
  for (const operator of usersJSON['operators']) {
    if (operator['email'] === email) return operator;
  }
}

export function getOperatorAwsId(usersJSON) {
  const decodedToken = parseJWTPayload(getToken());
  const email = decodedToken['email'];
  const operator = getOperatorByEmail(
    {
      operators: JSON.parse(JSON.stringify(usersJSON['operators'])),
    },
    email.toLowerCase()
  );

  return operator['awsId'];
}

export function invokeLambdaAsync(
  functionName,
  triggerFile,
  usersJSON,
  batchData,
  clientBucket
) {
  const lambdaClient = new AWS.Lambda(getOptions());
  const decodedToken = parseJWTPayload(getToken());
  const email = decodedToken['email'];
  const operator = getOperatorByEmail(usersJSON, email.toLowerCase());
  if (_.isUndefined(operator)) {
    alert('Unknown User');
    return;
  }
  const path = batchData['batch_path'].slice(
    batchData['batch_path'].indexOf('/') + 1
  );
  const bucket = batchData['batch_path'].split('/')[0];
  const jsonPayload = {
    Records: [
      {
        eventVersion: '2.0',
        eventSource: 'aws:s3',
        awsRegion: REGION,
        eventTime: new Date().toISOString(),
        eventName: 'ObjectCreated:Put',
        userIdentity: {
          principalId: `AWS:${operator['awsId']}:username`,
        },
        requestParameters: {
          sourceIPAddress: '127.0.0.1',
        },
        responseElements: {
          'x-amz-request-id': 'EXAMPLE123456789',
          'x-amz-id-2':
            'EXAMPLE123/5678abcdefghijklambdaisawesome/mnopqrstuvwxyzABCDEFGH',
        },
        s3: {
          s3SchemaVersion: '1.0',
          configurationId: 'testConfigRule',
          bucket: {
            name: bucket,
            ownerIdentity: {
              principalId: 'EXAMPLE',
            },
            arn: 'arn:aws:s3:::example-bucket',
          },
          object: {
            key: `${path}${triggerFile}`,
            size: 0,
            eTag: '0123456789abcdef0123456789abcdef',
            sequencer: '0A1B2C3D4E5F678901',
          },
        },
      },
    ],
  };
  if (clientBucket)
    jsonPayload['Records'][0]['s3']['bucket']['targetName'] = clientBucket;
  return lambdaClient
    .invokeAsync({
      FunctionName: functionName,
      InvokeArgs: JSON.stringify(jsonPayload),
    })
    .promise();
}

export function lambdaInvoke(functionName, payload) {
  const lambdaClient = new AWS.Lambda(getOptions());
  return lambdaClient
    .invoke({
      FunctionName: functionName,
      Payload: JSON.stringify(payload),
    })
    .promise();
}

function getCreds() {
  const token = getToken();
  const login = `cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`;
  const options = {
    IdentityPoolId: IDENTITY_POOL_ID,
    Logins: {},
  };
  options.Logins[login] = token;
  return new AWS.CognitoIdentityCredentials(options);
}

export class DB {
  getScanParams(dateFrom, dateTo) {
    return {
      TableName: 'batch-statuses',
      FilterExpression: 'updated_timestamp BETWEEN :s AND :e',
      ScanIndexForward: false,
      ExpressionAttributeValues: {
        ':s': (
          moment(dateFrom)
            .toDate()
            .getTime() / 1000
        ).toString(),
        ':e': (
          moment(dateTo)
            .toDate()
            .getTime() / 1000
        ).toString(),
      },
    };
  }

  async getItems(dateFrom, dateTo) {
    const docClient = new AWS.DynamoDB.DocumentClient(getOptions());
    const items = [];
    const scanParams = this.getScanParams(dateFrom, dateTo);
    let LastEvaluatedKey = '';

    do {
      let res = await docClient.scan(scanParams).promise();
      items.push(...res.Items);
      LastEvaluatedKey = res.LastEvaluatedKey;
      scanParams['ExclusiveStartKey'] = LastEvaluatedKey;
    } while (typeof LastEvaluatedKey !== 'undefined');
    return items;
  }
}

export class S3ObjectsManager {
  constructor(bucket) {
    this.s3 = new AWS.S3(getOptions());
    this.bucket = bucket;
  }

  async getObjectJSON(key) {
    let response = await this.s3
      .getObject({ Bucket: this.bucket, Key: key })
      .promise();
    return JSON.parse(
      response.Body.toJSON()
        .data.map((v) => String.fromCharCode(v))
        .join('')
    );
  }

  async listCommonPrefixes(prefix, delimiter) {
    let listResponse = [];
    let continuationToken = null;
    let commonPrefixes = [];
    do {
      listResponse = await this.s3
        .listObjectsV2({
          Bucket: this.bucket,
          Prefix: prefix,
          Delimiter: delimiter,
          ContinuationToken: continuationToken,
        })
        .promise();
      commonPrefixes.push(...listResponse['CommonPrefixes']);
      continuationToken = listResponse['NextContinuationToken'];
    } while (listResponse['isTruncated']);
    return commonPrefixes.map((p) => p['Prefix']);
  }

  async listPrefixes(prefix, delimiter) {
    let listResponse = [];
    let continuationToken = null;
    let prefixes = [];
    do {
      listResponse = await this.s3
        .listObjectsV2({
          Bucket: this.bucket,
          Prefix: prefix,
          Delimiter: delimiter || null,
          ContinuationToken: continuationToken,
        })
        .promise();
      prefixes.push(...listResponse['Contents'].map((item) => item['Key']));
      continuationToken = listResponse['NextContinuationToken'];
    } while (listResponse['isTruncated']);
    return prefixes;
  }
}
