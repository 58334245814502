<template>
  <v-app-bar app :color="navbarColor">
    <div class="d-flex align-center">
      <router-link :to="{ name: 'index' }">
        <v-img
          :src="require('../assets/logo.svg')"
          class="shrink mr-2 hidden-sm-and-down"
          alt="East View Information Services logo"
          contain
          width="150"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <v-btn
      v-for="(menuOption, index) in menuOptions"
      :key="index"
      :disabled="isCurrentRoute(menuOption.routeName)"
      @click="$router.push({ name: menuOption.routeName })"
      text
    >
      <span class="mr-2">{{ menuOption.title }}</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { EVColor } from '@/models/constants.js';

export default {
  name: 'NavbarComp',
  data() {
    return {
      navbarColor: EVColor.offWhite,
    };
  },
  computed: {
    menuOptions() {
      return [
        { title: 'Home', routeName: 'index' },
        { title: 'Publishing', routeName: 'report' },
        { title: 'Delivery', routeName: 'delivery' },
        // { title: 'Delete issue', routeName: 'deleteIssue' },
      ];
    },
  },
  methods: {
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
  },
};
</script>
