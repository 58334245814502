import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index.js';
import { redirectToAuthForm } from '@/services/auth';

Vue.use(Router);
const DEFAULT_TITLE = 'GPA Tools';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      name: 'index',
      meta: { title: DEFAULT_TITLE },
    },
    {
      path: '/report',
      component: () => import('@/views/Report'),
      name: 'report',
      meta: { title: 'Publishing Tool' },
    },
    {
      path: '/acronym/:collection/:acronym',
      component: () => import('@/views/AcronymByYear'),
      name: 'acronymByYear',
      meta: { title: 'Acronym Delivery Tool' },
    },
    {
      path: '/acronym/:collection/:acronym/:year',
      name: 'acronymByMonth',
      props: true,
      component: () => import('@/views/AcronymByMonth'),
      meta: { title: 'Acronym by month' },
    },
    {
      path: '/acronym/:collection/:acronym/:year/:monthName',
      name: 'acronymByDay',
      props: true,
      component: () => import('@/views/AcronymByDay'),
      meta: { title: 'Acronym by day' },
    },
    {
      path: '/delivery',
      component: () => import('@/views/Delivery'),
      name: 'delivery',
      meta: { title: 'Delivery Tool' },
    },
    {
      path: '/set-auth',
      component: () => import('@/views/SetAuth'),
      name: 'set-auth',
    },
    {
      path: '/delete/issue',
      component: () => import('@/views/DeleteIssue'),
      name: 'deleteIssue',
    },
    {
      path: '*',
      component: () => import('@/views/NotFound'),
      name: 'notFound',
    },
  ],
});

const setTitle = (to) => {
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  } else {
    window.document.title = DEFAULT_TITLE;
  }
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (!isAuthenticated && to.path !== '/set-auth') {
    // The user is not authenticated, time to redirect them to the login page
    return redirectToAuthForm();
  }

  next();
});

router.afterEach((to) => {
  setTitle(to);
});

export default router;
