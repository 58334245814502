import {
  REGION,
  APP_CLIENT_ID,
  REDIRECT_URI,
  DOMAIN,
} from '../config.constant';

const URL_PARAMS = new URLSearchParams(window.location.search);
const myHeaders = new Headers();
myHeaders.set('Cache-Control', 'no-store');

function getRandomString() {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map(
    (dec) => `0${dec.toString(16).substr(-2)}`
  );
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

async function encryptStringWithSHA256(str) {
  const PROTOCOL = 'SHA-256';
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return crypto.subtle.digest(PROTOCOL, encodedData);
}

function hashToBase64url(arrayBuffer) {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce(
    (acc, i) => `${acc}${String.fromCharCode(i)}`,
    ''
  );
  const decodedHash = btoa(stringifiedArrayHash);
  return decodedHash
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export async function redirectToAuthForm() {
  const state = getRandomString();
  const code_verifier = getRandomString();
  sessionStorage.setItem('code_verifier', code_verifier);
  sessionStorage.setItem('pkce_state', state);
  const arrayHash = await encryptStringWithSHA256(code_verifier);
  const code_challenge = hashToBase64url(arrayHash);
  sessionStorage.setItem('code_challenge', code_challenge);
  location.href =
    'https://' +
    DOMAIN +
    '.auth.' +
    REGION +
    '.amazoncognito.com/login?response_type=code&state=' +
    state +
    '&client_id=' +
    APP_CLIENT_ID +
    '&redirect_uri=' +
    REDIRECT_URI +
    '&scope=openid&code_challenge_method=S256&code_challenge=' +
    code_challenge;
}

export async function getToken() {
  const code_verifier = sessionStorage.getItem('code_verifier');
  const code = URL_PARAMS.get('code');
  const url =
    'https://' +
    DOMAIN +
    '.auth.' +
    REGION +
    '.amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=' +
    APP_CLIENT_ID +
    '&code_verifier=' +
    code_verifier +
    '&redirect_uri=' +
    REDIRECT_URI +
    '&code=' +
    code;

  let response = await fetch(url, {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return await response.json();
}

const decodePayload = (payload) => {
  const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = atob(cleanedPayload);
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2);
    return `${acc}%${uriEncodedChar}`;
  }, '');
  const jsonPayload = decodeURIComponent(uriEncodedPayload);
  return JSON.parse(jsonPayload);
};

//Parse JWT Payload
export const parseJWTPayload = (token) => {
  if (!token || token === 'undefined') {
    throw new Error('The authentication token was invalid!');
  }

  const [_, payload, __] = token.split('.');
  console.log(`${_} ${__}`);
  return decodePayload(payload);
};
