export const BatchDataOptions = Object.freeze({
  CHANGE_BATCH_STATUS: {
    name: 'Change batch status',
    value: 'changeBatchStatus',
  },
  COPY_TO_CLIENT: {
    name: 'Copy to Client',
    value: 'copyToClient',
  },
  MANUAL_PUBLISHING: {
    name: 'Manual Publishing',
    value: 'out-manual.start',
  },
  MOVE_TO_ARCHIVE: {
    name: 'Move to Archive',
    value: 'move-to-archive.start',
  },
  PUBLISH_TO_PRODUCTION: {
    name: 'Publish to Production',
    value: 'out-production.start',
  },
  PUBLISH_TO_STAGE: {
    name: 'Publish to Stage',
    value: 'out-stage.start',
  },
});

export const EVColor = Object.freeze({
  red: '#9d3039',
  green: '#3c734a',
  white: '#ffffff',
  offWhite: '#f7f7f7',
});

export const Statuses = Object.freeze({
  ARCHIVED: 'archived',
  PRODUCTION: 'production',
  STAGING: 'staging',
  INVALID: 'invalid',
});

export default {
  BatchDataOptions,
  EVColor,
  Statuses,
};
