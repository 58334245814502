<template>
  <div class="treeViewItem">
    <div v-if="isObject(data)" class="treeViewItem-leaf">
      <div class="treeViewItem-node" @click.stop="toggleOpen()">
        <span
          :class="{ opened: isOpen() }"
          class="treeViewItem-key treeViewItem-key__withChevron"
          >{{ getKey(data) }}</span
        >
        <span
          class="treeViewItem-hint"
          v-show="!isOpen() && data.children.length === 1"
          >{{ data.children.length }} property</span
        >
        <span
          class="treeViewItem-hint"
          v-show="!isOpen() && data.children.length !== 1"
          >{{ data.children.length }} properties</span
        >
      </div>
      <div v-if="!limitRenderDepth || isOpen()">
        <tree-view-item
          :key="getKey(child)"
          :max-depth="maxDepth"
          :current-depth="currentDepth + 1"
          v-show="isOpen()"
          v-for="child in data.children"
          :data="child"
          :modifiable="modifiable"
          :link="link"
          :limit-render-depth="limitRenderDepth"
          @change-data="onChangeData"
        />
      </div>
    </div>
    <div v-if="isArray(data)" class="treeViewItem-leaf">
      <div class="treeViewItem-node" @click.stop="toggleOpen()">
        <span
          :class="{ opened: isOpen() }"
          class="treeViewItem-key treeViewItem-key__withChevron"
          >{{ getKey(data) }}</span
        >
        <span
          class="treeViewItem-hint"
          v-show="!isOpen() && data.children.length === 1"
          >{{ data.children.length }} item</span
        >
        <span
          class="treeViewItem-hint"
          v-show="!isOpen() && data.children.length !== 1"
          >{{ data.children.length }} items</span
        >
      </div>
      <div v-if="!limitRenderDepth || isOpen()">
        <tree-view-item
          :key="getKey(child)"
          :max-depth="maxDepth"
          :current-depth="currentDepth + 1"
          v-show="isOpen()"
          v-for="child in data.children"
          :data="child"
          :modifiable="modifiable"
          :link="link"
          :limit-render-depth="limitRenderDepth"
          @change-data="onChangeData"
        />
      </div>
    </div>
    <tree-view-item-value
      v-if="isValue(data)"
      class="treeViewItem-leaf"
      :key-string="getKey(data)"
      :data="data.value"
      :modifiable="modifiable"
      :link="link"
      @change-data="onChangeData"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import TreeViewItemValue from './TreeViewItemValue.vue';

export default {
  components: {
    TreeViewItemValue,
  },
  name: 'tree-view-item',
  props: [
    'data',
    'max-depth',
    'current-depth',
    'modifiable',
    'link',
    'limit-render-depth',
  ],
  data() {
    return {
      open: this.currentDepth < this.maxDepth,
    };
  },
  methods: {
    isOpen() {
      return this.open;
    },
    toggleOpen() {
      this.open = !this.open;
    },
    isObject(value) {
      return value.type === 'object';
    },
    isArray(value) {
      return value.type === 'array';
    },
    isValue(value) {
      return value.type === 'value';
    },
    getKey(value) {
      if (_.isInteger(value.key)) {
        return `${value.key}: `;
      }
      return `${value.key}: `;
    },
    isRootObject(value = this.data) {
      return value.isRoot;
    },
    onChangeData(path, value) {
      const newPath = _.concat(this.data.key, path);
      this.$emit('change-data', newPath, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.treeViewItem {
  font-family: monaco, monospace;
  font-size: 14px;
  margin-left: 18px;

  &-node {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
  }

  &-leaf {
    white-space: nowrap;
  }

  &-key {
    font-weight: bold;

    &__withChevron {
      padding-left: 14px;

      &.opened::before {
        top: 4px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }

      &::before {
        color: #444;
        content: '\25b6';
        font-size: 10px;
        left: 1px;
        position: absolute;
        top: 3px;
        transition: -webkit-transform 0.1s ease;
        transition: transform 0.1s ease;
        transition: transform 0.1s ease, -webkit-transform 0.1s ease;
        -webkit-transition: -webkit-transform 0.1s ease;
      }

      & .treeViewItem-hint {
        color: #ccc;
      }
    }
  }
}
</style>
