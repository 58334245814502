<template>
  <v-app class="app">
    <navbar-comp />

    <v-main>
      <router-view />
    </v-main>

    <toast-comp />
  </v-app>
</template>

<script>
import NavbarComp from './components/NavbarComp.vue';
import ToastComp from './components/ToastComp.vue';

export default {
  name: 'App',
  components: { NavbarComp, ToastComp },
};
</script>

<style lang="scss" scoped>
.app {
  & ::v-deep .v-main__wrap {
    margin: 1em 0;
    padding: 0 1em;
  }
}
</style>
