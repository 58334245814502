import Vue from 'vue';
import router from './router';
import store from './store';
import TreeViewItem from '@/components/TreeViewItem';
import TreeView from '@/components/TreeView';
import { AgGridVue } from 'ag-grid-vue';
import TreeViewItemValue from '@/components/TreeViewItemValue';
import App from './App';
import { mapGetters } from 'vuex';
import AWS from 'aws-sdk';
import { REGION } from './config.constant';
import vuetify from './plugins/vuetify';
import {mask} from 'vue-the-mask'

AWS.config.update({ region: REGION });

Vue.directive('mask', mask);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.component('ag-grid-vue', AgGridVue);
Vue.component('tree-view-item-value', TreeViewItemValue);
Vue.component('tree-view-item', TreeViewItem);
Vue.component('tree-view', TreeView);

Vue.mixin({
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
