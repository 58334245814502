<template>
  <v-snackbar
    :color="toastColor"
    :timeout="toastData.timer"
    v-model="toastData.show"
  >
    <v-icon v-if="toastData.icon" left>{{ toastData.icon }}</v-icon>
    {{ toastData.message }}

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" @click="hideSnackbar" text>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { mutationTypes } from '@/store/index.js';
import { EVColor } from '@/models/constants.js';

export default {
  name: 'toast-comp',
  computed: {
    ...mapState({
      toastData: (state) => state.toast,
    }),
    toastColor() {
      return this.toastData?.color || EVColor.red;
    },
  },
  methods: {
    ...mapMutations({
      setToast: mutationTypes.SET_TOAST,
    }),
    hideSnackbar() {
      this.setToast({ show: false });
    },
  },
};
</script>
